"use client";
import React, {useState, ReactNode, useEffect} from "react";
import Sidebar, {Notification} from "@/components/Sidebar";
import Header from "@/components/Header";
import {toast, Toaster} from "react-hot-toast";
import {createClient} from "@/utils/supabase/client";
import {redirect} from "next/navigation";
import {User} from "@supabase/auth-js";
import {Company} from "@/types/company";

export default function DefaultLayout({
                                          children,
                                      }: {
    children: React.ReactNode;
}) {
    const supabase = createClient();
    const [session, setSession] = useState<User>();
    const [company, setCompany] = useState<Company>();
    const [permissions, setPermissions] = useState<string[]>([]);
    const [notifications, setNotifications] = useState<Notification[]>([]);

    const getNotifications = async () =>{
        supabase.from('notifications').select().then(({data: notifications}) => setNotifications((notifications as Notification[])))
    }

    //TODO move from here
    useEffect(() => {
        supabase.auth.getUser().then(({data: {user}}) => {
            if (user) {
                setSession(user);
                supabase.from('company').select().eq('tenant_id', user.user_metadata.tenant_id ?? user.id).single().then(({data: company}) => {
                    setCompany(company)
                    const channel = supabase
                        .channel('schema-db-changes')
                        .on(
                            'postgres_changes',
                            {
                                event: 'INSERT',
                                schema: 'public',
                                table: 'notifications',
                                filter: `tenant_id=eq.${company?.tenant_id}`,
                            },
                            (payload) => {
                                console.log(payload)
                                const newNotification = payload.new as Notification;
                                toast.success(newNotification.payload.message)
                                setNotifications((prev) => [newNotification, ...prev]);
                                console.log(newNotification)
                            }
                        )
                        .on(
                            'postgres_changes',
                            {
                                event: 'UPDATE',
                                schema: 'public',
                                table: 'notifications',
                                filter: `tenant_id=eq.${company?.tenant_id}`,
                            },
                            (payload) => {
                                const updatedNotification = payload.new as Notification;
                                setNotifications((prev) =>
                                    prev.map((notif) =>
                                        notif.id === updatedNotification.id ? updatedNotification : notif
                                    )
                                );
                                console.log(updatedNotification)
                                toast.success(updatedNotification.payload.message);
                            }
                        )
                        .subscribe((status) => {
                            console.log(status)
                        })
                    return () => {
                        // supabase.removeChannel(channel);
                    };
                })
                supabase.from("users")
                    .select()
                    .eq('user_id', user?.id)
                    .single()
                    .then(({data: user}) => {
                        setPermissions(user?.permissions)
                    })
            } else redirect("/signin");
        });
        getNotifications()
    }, []);
    const [sidebarOpen, setSidebarOpen] = useState(false);
    return (
        <>
            {/* <!-- ===== Page Wrapper Start ===== --> */}
            <div className="flex h-screen overflow-hidden">
                {/* <!-- ===== Sidebar Start ===== --> */}
                <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} session={session}
                         permissions={permissions} company={company} notifications={notifications}/>
                {/* <!-- ===== Sidebar End ===== --> */}

                {/* <!-- ===== Content Area Start ===== --> */}
                <div className="relative flex flex-1 flex-col overflow-y-auto overflow-x-hidden">
                    {/* <!-- ===== Header Start ===== --> */}
                    <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} image={company?.logo}/>
                    {/* <!-- ===== Header End ===== --> */}
                    <Toaster position="bottom-right"/>
                    {/* <!-- ===== Main Content Start ===== --> */}
                    <main>
                        <div className="h-full mx-auto max-w-screen-2xl p-4 md:p-6 2xl:p-10">
                            {children}
                        </div>
                    </main>
                    {/* <!-- ===== Main Content End ===== --> */}
                </div>
                {/* <!-- ===== Content Area End ===== --> */}
            </div>
            {/* <!-- ===== Page Wrapper End ===== --> */}
        </>
    );
}
